import $ from 'jquery';

class ScrollTo {
  constructor(el) {
    this._scroll(this, el);
  }

  _getTargetPosition(el) {
    const target = $(el).data().scrollTo;
    const targetPos = $(target).offset().top;

    return targetPos;
  }

  _scroll(_this, el) {
    const headerHeight = 108;

    $(el).on('click', function() {
      $('html, body').animate({
        scrollTop: _this._getTargetPosition(el) - headerHeight
      }, '500');

      return false;
    });
  }
}

export default ScrollTo;
