import $ from 'jquery';

class VideoController {
  constructor(el) {
    const _this = this;
    const playerBtn = $(el).find('[data-video="togglePlay"]');

    playerBtn.on('click', function() {
      _this._togglePlay(el);
    })
  }

  _togglePlay(el) {
    const video = $(el).find('[data-video="view"]').get(0);
    const playerBtn = $(el).find('[data-video="togglePlay"]');

    if (video.paused) {
      video.play();
      playerBtn.addClass('is-active');
    } else {
      video.pause();
      playerBtn.removeClass('is-active');
    }
  }
}

export default VideoController;
