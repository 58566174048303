import $ from 'jquery';

class ToggleHeaderNav {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    const headerNav = $('#header-nav');

    $(el).on('click', function() {
      headerNav.toggle();

      return false;
    });

    $(document).click(function(){
      headerNav.hide();
    });

    /* Clicks within the dropdown won't make
       it past the dropdown itself */
    headerNav.click(function(e){
      e.stopPropagation();
    });
  }
}

export default ToggleHeaderNav;
